import {embed_images, unembed_images} from 'mobile/utils/embed.js';
import {froala_setup, editor_setup, init_froala_setup,
				update_editor_attachments, find_editor_attachments_update_delete,
				find_editor_attachments, find_editor_attachments_after} 
				from 'mobile/froala/setup.js';

import {update_project_list_view_columns} from 'mobile/utils/project_board.js'

import {} from 'mobile/utils/gravatar.js';
require("mobile/utils/jquery.autosize.js")
import {developement} from 'mobile/utils.js';

var init_comments_page;

$(document).ready(function() {
  if ($('.workspace.project-board').length > 0 || $('.workspace.dashboard').length > 0 || $('.workspace.task-board').length > 0 || $('.workspace.public-card').length > 0 || $('.workspace.calendar-fluid').length > 0 || $('.workspace.home').length > 0) {
    init_comments_page();
  }
  $(document).on("breeze:page", function(e, page) {
    if (page === "comments") {
      return init_comments_page();
    }
  });
});

init_comments_page = function() {
  var close_boxes;
  Breeze.comment_editor = '<div class="comment-editor clearfix"> <div class="comment-area row"> <div class="wysi-placeholder "> <div id="editor-element" placeholder="Add your comment" name="comment[description]"></div> </div> <div  class="span8 task-footer"> <div class="span5 comment-attach-slide"> <a href="#" class="attach-slide">Attach files ...</a> <span class="attach-select hide"> <span>Attach files from ... </span> <a href="#" class="attach-google">Google Drive</a> <a href="#" class="attach-dropbox">Dropbox</a> <a href="#" class="attach-boxnet">Box</a> <a href="#" class="attach-onedrive">OneDrive</a> <a href="#" class="temp_attach">Computer</a> </span> </div> <div class="comment-attach"> <div class="upload-progress"></div> </div> </div> <div class="submit span7"> <button class="c-button c-button--blue c-button-med update" data-action="update" type="submit" > <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> <span class="cname">Update comment</span> <div class="progress-button"> <div class="indeterminate"></div> </div> </button> <button class="c-button c-button--white c-button-med cancel" type="button"> <div class="c-ripple js-ripple"> <span class="c-ripple__circle"></span> </div> Cancel </button> </div> </div> </div>';
  $('#card_modal .comments, .public-card .card-comments').on("click", ' .comment-box[data-comment-owner="true"] .comment-body .breeze-checklist', function(e) {
    return e.stopPropagation();
  });
  $('#card_modal .comments, .public-card .card-comments').on("change", '.comment-body .breeze-checklist', function(e) {
    var card, comment, comment_id, hidden, socket_params;
    if (this.checked) {
      this.setAttribute("checked", "true");
    } else {
      this.removeAttribute("checked");
    }
    $(this).parent().toggleClass("breeze-checklist-checked");
    comment = $(this).closest('.comment-body').html();
    comment_id = $(this).closest('.comment-box').attr('data-comment-id');
    hidden = $(this).closest('.comment-box').attr('data-hidden');
    card = Breeze.Card.id;
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-comment-update",
      user: Breeze.current_user.email,
      comment: comment_id,
      hidden: hidden
    };
    return $.ajax({
      type: "PUT",
      url: '/cards/' + card + '/comments/' + comment_id,
      data: {
        project_id: Breeze.Card.project,
        comment: comment
      }
    });
  });
	
	$('#card_modal .comments, .public-card .card-comments').on("click", '.load-comments', function() {
	  var ell, page, url;
    url = "/cards/" + Breeze.Card.id + "/comments?auth_token=" + Breeze.current_user.token;
	  ell = $(this);
	  page = parseInt(ell.attr('data-page'), 10) + 1;
	  $('#card_modal  .load-comments-box .m-loader').remove();
	  $.ajax({
	    type: "GET",
	    url: url,
	    data: {
	      project_id: Breeze.Card.project,
	      page: page,
	      partial: true
	    },
	    beforeSend: function() {
	      $('#card_modal  .load-comments-box .load-comments').hide();
	      return $('#card_modal  .load-comments-box').append(Breeze.global_loader);
	    },
	    success: function(data) {
	      $('#card_modal  .load-comments-box .m-loader').remove();
	      $('#card_modal  .load-comments-box .load-comments').show();
	      ell.attr('data-page', page);
	      if (Breeze.current_user.comments_newest) {
	        $(data).find('.comment-box').insertBefore($("#card_modal .comments .load-comments-box"));
	      } else {
	        $(data).find('.comment-box').insertAfter($("#card_modal .comments .load-comments-box"));
	      }
	      if ($(data).find('.load-comments').length <= 0) {
	        $("#card_modal .comments .load-comments-box").remove();
	      }
	      return embed_images($('#card_modal .comment-body'));
	    }
	  });
	  return false;
	});
	
  $('#card_modal .comments, .public-card .card-comments').on("click", '.add-reply', function() {
    var message_box = $(this).closest(".comment-box");
    var message_author = message_box.find(".comment-user").html();
    var message_date = message_box.find(".comment-anchor").html();
    var message = "<blockquote class='reply-quote'><i><span style='font-size: 12px;'><strong>" + message_author + " - " + message_date + "</strong></span></i><div>" + message_box.find(".comment-body").html() + "</div></blockquote><p><br></p>";
    
    if ($("#editor-element").length <= 0) {
      $(".new-comment-box .card-comment").trigger("click");
    }

    $("#editor-element").froalaEditor("html.insert", message);

    $("#editor-element").froalaEditor("events.focus");
    return false;
  });
  $('#card_modal .comments, .public-card .card-comments').on("click", ".card-comment", function(e) {
    var card, el, project, r, that;
    $(".temp_attach").show();
    $(".card-editor form .update").trigger("click");
    Breeze.current_user.comments_hide_create = false;
    if ($('.comment-editor').length > 0) {
      r = confirm("You have unsaved work. Would you like to discard all changes?");
      if (r) {
        $(".comment-editor .submit .cancel").trigger("click");
      } else {
        return false;
      }
    }
    project = $('#card_modal').data('current_project');
    card = $('#card_modal').data('current_card');
    if ($(".comment-editor").length) {
      $(".comment-editor").parent().find(".comment-content").show();
      $(".comment-editor").remove();
    }
    el = $(Breeze.comment_editor);
    el.find('.comment-area').append('<div class="upload-overlay-placeholder"> <i class="icon-upload"></i> <div>Drop here to upload files ...</div> </div> <div class="upload-overlay"></div>');
    that = $(this);
    if (Breeze.current_user.comments_newest) {
      $(el).insertAfter($(this).parent());
    } else {
      $(this).parent().parent().append(el);
    }
    $(".comment-editor").data("new-comment", true);
    $(".comment-editor").data("attach", []);
    $(".comment-editor .submit .update").removeClass("update").addClass("create").find('.cname').text("Add comment");
    $('.comments-dropdown').removeClass("open");
		init_froala_setup()
    $('#editor-element').on('froalaEditor.initialized', function(e, editor) {
      editor_setup(editor);
      $("#editor-element").froalaEditor('events.focus');
      if (!Breeze.current_user.comments_newest) {
        return $(".modal-backdrop").animate({
          scrollTop: $('.card-content').height()
        }, "400");
      }
    });
    $('#editor-element').froalaEditor(froala_setup);
    $("#editor-element").froalaEditor("html.set", " ");
    $("#editor-element").data('project', Breeze.Card.project || $('.public-card .card-content').attr('data-project-id')).data('card', Breeze.Card.id || $('.public-card .card-content').attr('data-card-id')).data('new_comment', true);
    $("#editor-element").froalaEditor('events.focus');
    if (!Breeze.current_user.observer || Breeze.current_user.can_see_client_comments) {
      $('.fr-toolbar [data-cmd="eyeslash"]').show();
    }
    if (Breeze.current_user.comments_hide || Breeze.current_user.team_id === 24983) {
      $('.fr-toolbar [data-cmd="eyeslash"]').hide();
    }
    $('.modal-body').removeClass('dropzone');
    $('.upload-overlay-placeholder').addClass('hide');
    find_editor_attachments();
    that.parent().hide();
    return false;
  });
  close_boxes = function(box) {
    var comment_box, that;
    that = $(box).closest(".comment-editor");
    comment_box = that.siblings(".comment-content");
    $('.froala-view').atwho('destroy');
    $("#editor-element").froalaEditor("destroy");
    $('.modal-body').addClass('dropzone');
    $('.upload-overlay-placeholder').removeClass('hide');
    $(comment_box).closest('.comment-box').addClass('dropzone');
    if (that.data("new-comment")) {
      that.remove();
      return $(".card-comment").parent().show();
    } else {
      that.remove();
      embed_images(comment_box.find('.comment-body'));
      return comment_box.show();
    }
  };
  $('#card_modal .comments, .public-card .card-comments').on("click", ".comment-editor .submit .cancel", function() {
    update_editor_attachments();
    close_boxes(this);
    return false;
  });
  $('#card_modal .comments, .public-card .card-comments').on("click", ".comment-editor .submit .update", function() {
    var added_attach_ids, card, cc, comment, comment_box, comment_id, container, e, el, hidden, i, mentions, project, ref, scanned_mentions, socket_params, that;
    el = $(this);
    container = $(this).closest('.card-comments');
    project = container.attr('data-pid');
    card = container.attr('data-cid');
    that = $(this).closest(".comment-editor");
    comment_box = that.siblings(".comment-content");
    cc = $("#editor-element").froalaEditor('html.get');
    cc = $("<div>" + cc + "</div>");
    $(cc).find('img.loading-dots').remove();
    comment = $(cc).html();
    comment_id = comment_box.parent().data("comment-id");
    comment_box.find(".comment-body").html(comment);
    hidden = $(this).closest('.comment-box').attr('data-hidden');
    mentions = [];
    scanned_mentions = [];
    $('<div>' + comment + '</div>').find('.atwho-inserted').each(function() {
      return scanned_mentions.push($(this).text());
    });
    ref = Breeze.mentions;
    for (i in ref) {
      e = ref[i];
      if (_.contains(scanned_mentions, e.name)) {
        mentions.push(e.id);
      }
    }
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-comment-update",
      user: Breeze.current_user.email,
      comment: comment_id,
      hidden: hidden
    };
    added_attach_ids = find_editor_attachments_after();
    $.ajax({
      type: "PUT",
      url: '/cards/' + card + '/comments/' + comment_id,
      data: {
        project_id: project,
        comment: comment,
        hidden: hidden,
        mentions: mentions,
        added_attach: added_attach_ids,
        deleted_attach: find_editor_attachments_update_delete(added_attach_ids)
      },
      beforeSend: function() {
        el.prop('disabled', true);
        return el.find('.progress-button').show();
      },
      error: function() {
        el.prop('disabled', false);
        return el.find('.progress-button').hide();
      },
      success: function(data) {
        if (data.indexOf("breezeok") > -1) {
          $('.froala-view').atwho('destroy');
          $("#editor-element").froalaEditor("destroy");
          that.remove();
          embed_images(comment_box.find('.comment-body'));
          comment_box.show();
          $('.modal-body').addClass('dropzone');
          $('.upload-overlay-placeholder').removeClass('hide');
          comment_box.closest('.comment-box').addClass('dropzone');
          if ($('#card_modal .comments [data-comment-id="' + comment_id + '"]').length > 0) {
            socket_params.message = $('#card_modal .comments [data-comment-id="' + comment_id + '"]').prop('outerHTML');
          } else {
            socket_params.message = "";
          }
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        } else {
          return $.flashMessage("<span class='red-text'>We couldn't update the comment. Please refresh your browser window.</span>", true);
        }
      }
    });
    return false;
  });
  $('#card_modal .comments, .public-card .card-comments').on("click", ".comment-editor .submit .create", function(e, attach) {
    var added_attach_ids, avatar_img, card, cc, comment, container, el, elc, hidden, i, mentions, project, ref, scanned_mentions, socket_params, temp_comment, user_email, user_id, user_name;
    elc = $(this);
    container = $(this).closest('.card-comments');
    project = container.attr('data-pid');
    card = container.attr('data-cid');
    cc = $("#editor-element").froalaEditor('html.get');
    cc = $("<div>" + cc + "</div>");
    $(cc).find('img.loading-dots').remove();
    comment = $(cc).html();
    embed_images($("#editor-element"));
    temp_comment = "comment_temp_" + Breeze.current_user.id + new Date().getTime();
    user_id = $(".user_ident").data("current-user-id");
    user_name = $(".user_ident").data("current-user-name");
    user_email = $(".user_ident").data("current-user-email");
    attach = $(".comment-editor").data("attach");
		

    $('.comment-editor').find('[data-attach-id]').each(function() {
      return attach.push($(this).attr('data-attach-id'));
    });
    hidden = false;
    hidden = Breeze.current_user.comments_hide_create;
    if (Breeze.current_user.comments_hide || Breeze.current_user.team_id === 24983) {
      hidden = true;
    }
    if ($('.public-project').length > 0) {
      avatar_img = "";
    } else {
      avatar_img = '<img src="' + (Breeze.current_user.avatar || "https://d1wdkw032nxkks.cloudfront.net/assets/avatar.png") + '" size="80" class="avatar_card">';
    }
		el = `<div id="${temp_comment}" class="comment-box dropzone hide" data-comment-owner="true" data-comment-id="" data-tid="${temp_comment}" data-hidden="${hidden}">
			<div class="upload-overlay-placeholder">
				<i class="icon-upload"></i>
				<div>Drop here to upload files ...</div>
			</div>
			<div class="upload-overlay"></div>
			<div class="comment-avatar" style="background-color:#${Breeze.current_user.color}">
				${avatar_img}
				<p class="member_initials">${Breeze.current_user.initials}</p>
			</div>
			<div class="comment-content">
				<div class="comment-head">
					<span class="comment-user">${Breeze.current_user.name}</span>
					<span class="hide-message ${hidden ? "show" : void 0}">Clients can't see this comment</span>
				</div>
				<div class="row">
					<span class="span8 comment-body">${comment}</span>
				</div>
				<div class="attachments">${$(".new_attachments").html()}</div>
				<div class="row comment-footer-row">
					<div class="span7 comment-footer">
						<span>
							<a href="#${temp_comment}" class="comment-anchor">Posted just now</a>
							<span class="reactions"><span class="add-reaction" title="Reactions"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="#999" stroke="#999" stroke-width="5" d="M208 96C93.1 96 0 189.1 0 304s93.1 208 208 208 208-93.1 208-208S322.9 96 208 96zm0 384c-97 0-176-79-176-176s79-176 176-176 176 79 176 176-79 176-176 176zm75.8-130.7C265 371.4 237.4 384 208 384s-57-12.6-75.8-34.6c-5.7-6.7-15.9-7.5-22.5-1.8-6.8 5.8-7.5 15.8-1.8 22.6C132.7 399.3 169.2 416 208 416s75.3-16.7 100.2-45.9c5.8-6.7 4.9-16.8-1.8-22.6-6.6-5.6-16.8-4.9-22.6 1.8zM144 280c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zm128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24-24 10.7-24 24 10.7 24 24 24zM632 96h-88V8c0-4.4-3.6-8-8-8h-16c-4.4 0-8 3.6-8 8v88h-88c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88v88c0 4.4 3.6 8 8 8h16c4.4 0 8-3.6 8-8v-88h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8z"></path></svg></span></span>
							<span class="add-reply" title="Reply"><svg fill="currentColor" stroke="currentColor" stroke-width="5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" stroke="currentColor" stroke-width="5" d="M216 160c-13.3 0-24-10.7-24-24l0-8 0-16 0-48L32 208 192 352l0-48 0-16 0-8c0-13.3 10.7-24 24-24l8 0 96 0c70.7 0 128 57.3 128 128c0 8.3-.7 16.1-2 23.2c18.2-23.4 34-57.1 34-103.2c0-79.5-64.5-144-144-144l-112 0-8 0zm8 144l0 16 0 32c0 12.6-7.4 24.1-19 29.2s-25 3-34.4-5.4l-160-144C3.9 225.7 0 217.1 0 208s3.9-17.7 10.6-23.8l160-144c9.4-8.5 22.9-10.6 34.4-5.4s19 16.6 19 29.2l0 32 0 16 0 16 32 0 80 0c97.2 0 176 78.8 176 176c0 113.3-81.5 163.9-100.2 174.1c-2.5 1.4-5.3 1.9-8.1 1.9c-10.9 0-19.7-8.9-19.7-19.7c0-7.5 4.3-14.4 9.8-19.5c9.4-8.8 22.2-26.4 22.2-56.7c0-53-43-96-96-96l-64 0-32 0 0 16z"></path></svg></span>
              <a href="#" class="comment-edit-action"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="more-icon"><path d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path></svg></a>
						</span>
					</div>
				</div>
			</div>
		</div>`;
    mentions = [];
    scanned_mentions = [];
    $('<div>' + comment + '</div>').find('.atwho-inserted').each(function() {
      return scanned_mentions.push($(this).text());
    });
    ref = Breeze.mentions;
    for (i in ref) {
      e = ref[i];
      if (_.contains(scanned_mentions, e.name)) {
        mentions.push(e.id);
      }
    }
    socket_params = {
      card: card,
      project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
      method: "card-comment",
      user: Breeze.current_user.email,
      hidden: hidden
    };
    if ($('.public-card').length > 0) {
      $(el).hide().insertBefore('.public-card .card-comments .comment-avatar:last');
    } else {
      if (Breeze.current_user.comments_newest) {
        $(el).hide().insertAfter('#card_modal .card-comments .new-comment-box');
      } else {
        $(el).hide().insertBefore('#card_modal .card-comments .comment-avatar:last');
      }
    }
    added_attach_ids = find_editor_attachments_after();

    $.ajax("/cards/" + card + "/comments", {
      type: "POST",
      data: {
        card: {
          project_id: project,
          comment: comment,
          temp_comment: temp_comment,
          attach: attach,
          hidden: hidden
        },
        mentions: mentions,
        added_attach: added_attach_ids,
        deleted_attach: find_editor_attachments_update_delete(added_attach_ids)
      },
      beforeSend: function() {
        elc.prop('disabled', true);
        return elc.find('.progress-button').show();
      },
      error: function(data) {
        elc.prop('disabled', false);
        elc.find('.progress-button').hide();
        return Bugsnag.notify('Comment add failed', 'Response: ' + data.responseText + ' Code:' + data.status + ' Status:' + data.statusText, {}, 'error');
      },
      success: function(data) {
				
				var c_id = parseInt(data,10)
        var comment_count, comment_element;
        if (c_id >  0) {
					
					
					$('#'+temp_comment).find('.comment-anchor').attr('href','#comment_'+c_id)
					$('#'+temp_comment).attr("id",'comment_'+c_id).attr("data-comment-id",c_id).removeClass("hide").show();


          $('.froala-view').atwho('destroy');
          $("#editor-element").froalaEditor("destroy");
          $(".comment-editor").remove();
          $(".card-comment").parent().show();
          $('.modal-body').addClass('dropzone');
          $('.upload-overlay-placeholder').removeClass('hide');
          comment_element = $('.card[data-card-id="' + card + '"]').find('.comment-bubble span');
          if (comment_element.length) {
            comment_count = parseInt(comment_element.html());
            comment_element.html(comment_count + 1);
          } else {
            $('.card[data-card-id="' + card + '"]').find('.badges .card-comment-bubble-container').append('<span class="comment-bubble"> <i class="icon-comment" title="comments"></i> <span>1</span> </span>');
          }
          $(".new_attachments").html('<div class="boxes"></div>');
					update_project_list_view_columns("comment")
          if ($('#card_modal .comments [data-tid="' + temp_comment + '"]').length > 0) {
            socket_params.message = $('#card_modal .comments [data-tid="' + temp_comment + '"]').prop('outerHTML');
          } else {
            socket_params.message = "";
          }
          if (Breeze.is_socket_connected()) {
            return Breeze.socket.emit("message", socket_params);
          }
        } else {
           $.flashMessage("<span class='red-text'>We had problems adding the comment. Please reload the app.</span>", true);
        }
      }
    });
    return false;
  });
  $('#card_modal .comments, .public-card .card-comments').on("click", '.comment-content .comment-body a', function(e) {
    // window.open($(this).attr("href"), '_blank');
    // return false;
		e.stopPropagation()
  });
  $('#card_modal .comments, .public-card .card-comments').on('click', '.comment-content a.edit, .comment-content .comment-body', function(e) {
    var card, comment, comment_box, el, h, project, r, that;
    $('.popover').remove();
    $('.comments-dropdown').removeClass("open");
    if (!$(this).closest(".comment-box").data("comment-owner")) {
      return false;
    }
    if (getSelection().toString() !== "" && e.target.contains(getSelection().anchorNode)) {
      return false;
    }
    $(".card-editor form .update").trigger("click");
    if ($('.comment-editor').length > 0) {
      r = confirm("You have unsaved work. Would you like to discard all changes?");
      if (r) {
        $(".comment-editor .submit .cancel").trigger("click");
      } else {
        return false;
      }
    }
    $(".temp_attach").show();
    project = $('#card_modal').data('current_project');
    card = $('#card_modal').data('current_card');
    if ($(".comment-editor").length) {
      $(".comment-editor").parent().find(".comment-content").show();
      $(".card-comment").parent().show();
      $(".comment-editor").remove();
    }
    comment_box = $(this).closest(".comment-box");
    unembed_images(comment_box.find(".comment-body"));
    comment = comment_box.find(".comment-body").html();
    that = $(this);
    h = comment_box.find(".comment-body").height();
    el = $(Breeze.comment_editor);
    that.closest(".comment-box").append(el);
		init_froala_setup()
    $('#editor-element').on('froalaEditor.initialized', function(e, editor) {
      editor_setup(editor);
      return $("#editor-element").froalaEditor('events.focus');
    });
    $('#editor-element').froalaEditor(froala_setup);
    $("#editor-element").froalaEditor("html.set", comment);
    $("#editor-element").data('project', Breeze.Card.project || $('.public-card .card-content').attr('data-project-id')).data('card', Breeze.Card.id || $('.public-card .card-content').attr('data-card-id')).data('comment', comment_box.attr('data-comment-id') || comment_box.attr('data-tid'));
    $("#editor-element").froalaEditor('events.focus');
    $('.modal-body').removeClass('dropzone');
    $('.upload-overlay-placeholder').addClass('hide');
    comment_box.removeClass('dropzone');
    that.closest(".comment-content").hide();
    find_editor_attachments();
    return false;
  });
  $('#card_modal .comments, .public-card .card-comments').on("click", ".comment-content .breeze-uploaded-image", function(e) {
    return Breeze.init_image_magnify($(this));
  });
  $('#card_modal .comments, .public-card .card-comments').on("click", '.comment-edit-action', function() {
    var comment, comment_id, el, hidden, observer, popover_html;
    el = $(this);
    comment = $(this).closest('.comment-box');
    comment_id = comment.attr('data-comment-id');
    if (comment.attr('data-hidden') === "true") {
      hidden = "active";
    } else {
      hidden = "";
    }
    if (Breeze.current_user.observer || !Breeze.current_user.can_see_client_comments) {
      observer = "hide";
    } else {
      observer = "";
    }
    if ($('#comment-action-menu[data-id="' + comment_id + '"]').length > 0) {
      el.popover("hide");
      el.removeData("bs.popover");
      $('#comment-action-menu').remove();
    } else {
      el.removeData("bs.popover");
      $('#comment-action-menu').remove();
      popover_html = $('<div> <ul class="dropdown-menu"> <li><a href="#" data-action="edit">Edit</a></li> <li class="' + observer + '"><a href="#" data-action="hidden" class="' + hidden + '">Hide from clients</a></li> <li class="divider"><a href="#"></a></li> <li><a href="#" data-action="delete"><span class="fa fa-trash-o" aria-hidden="true"></span>Delete</a></li> </ul> </div>');
      
			var elt = el //.parent().parent()
			
			
			el.popover({
        trigger: "manual",
				display1: "static",
        container: elt,
        placement1: "bottom",
        template: '<div class="popover todo-menu"><div id="comment-action-menu" data-id="'+comment_id+'" class="popover-inner"><div class="popover-body dropdown-check"><div></div></div></div></div>',
        html: true,
				animation: false,
				// offset: 85,
				boundary1: el,
				sanitize: false,
				popperConfig1: {
					positionFixed: true,
					placement: 'bottom'
				},
        content: popover_html.html()
      });

      el.popover("show");

			
      $('#comment-action-menu').on("click", '[data-action="edit"]', function() {
        $('.comment-box[data-comment-id="' + comment_id + '"] .comment-body').trigger("click");
        return false;
      });
      $('#comment-action-menu').on("click", '[data-action="hidden"]', function() {
        var card, project;
        if ($(this).hasClass("active")) {
          $(this).removeClass("active");
          hidden = false;
          comment.find('.hide-message').removeClass('show');
        } else {
          $(this).addClass("active");
          hidden = true;
          comment.find('.hide-message').addClass('show');
        }
        comment.attr('data-hidden', hidden);
        card = Breeze.Card.id;
        project = Breeze.project;
        $.ajax({
          type: "PUT",
          queue: true,
          url: '/cards/' + card + '/comments/' + comment_id,
          data: {
            project_id: project,
            hidden: hidden
          }
        });
        return false;
      });
      $('#comment-action-menu').on("click", '[data-action="delete"]', function() {
        var card, comment_count, comment_element, elb, project, socket_params;
        card = Breeze.Card.id;
        project = Breeze.project;
        comment_element = $('.card[data-card-id="' + card + '"]').find('.comment-bubble span');
        elb = $(this);
        if (comment_element.length) {
          comment_count = parseInt(comment_element.html()) - 1;
          if (comment_count > 0) {
            comment_element.html(comment_count);
          } else {
            comment_element.closest('.comment-bubble').remove();
          }
        }
				update_project_list_view_columns("comment")
        socket_params = {
          card: card,
          project: Breeze.project_token != null ? Breeze.project_token : $('.card[data-card-id="' + card + '"]').attr('data-project-token'),
          method: "card-comment-delete",
          comment: comment_id
        };
        $.ajax({
          type: "DELETE",
          queue: true,
          url: '/cards/' + card + '/comments/' + comment_id,
          data: {
            project_id: project
          },
          success: function(data) {
            if (Breeze.is_socket_connected()) {
              return Breeze.socket.emit("message", socket_params);
            }
          }
        });
        $('.comment-box[data-comment-id="' + comment_id + '"]').remove()
        return false;
      });
    }
    return false;
  });
};
