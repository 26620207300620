var init_projects_list;
import {developement} from 'mobile/utils.js';
import dateFormat from 'dateformat';

$(document).ready(function() {
  $(document).on("breeze:page", function(e, page) {
    if (page === "projects_list") {
      return init_projects_list();
    }
  });
  if ($('.workspace.projects-list').length > 0) {
    return init_projects_list();
  }
});

const is_current_year = (date) => {
  return new Date(date).getUTCFullYear() === new Date().getUTCFullYear();
};

const human_date_range = (startdate, enddate) => {
  return [human_date(startdate), human_date(enddate)].filter(Boolean).join(" - ");
};

const human_date = (date) => {
  if (date && date.length > 0) {
    if (is_current_year(date)) {
      return dateFormat(date, "UTC:mmm d");
    } else {
      // show short date on mobile because of space constraints
      // return dateFormat(date, "UTC:mmm d, yyyy"); 
      return dateFormat(date, "UTC:mmm d");

    }
  } else {
    return "";
  }
};
const highlight_overdue_projects = function() {
  const today = new Date().setHours(0,0,0,0);
  const day = 86400000;

  $('.project-settings-dropdown').each(function() {
    $(this).parent().find('.duedates').text(human_date_range($(this).attr('data-startdate'), $(this).attr('data-duedate')));

    if ($(this).attr('data-duedate') && $(this).attr('data-duedate').length > 0) {
      const d = new Date($(this).attr('data-duedate'));
      const duedate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 0, 0, 0).setHours(0,0,0,0);
      
      if (duedate === today) {
        $(this).parent().find('.duedates').addClass("red");
        $(this).parent().find('.icon-calendar').addClass("red");
      }
      else if (duedate === (today - day)) {
        $(this).parent().find('.duedates').addClass("red"); 
        $(this).parent().find('.icon-calendar').addClass("red");
      }
      else if (duedate === (today + day)) {
        $(this).parent().find('.duedates').addClass("yellow");
        $(this).parent().find('.icon-calendar').addClass("yellow");
      }
      else {
        $(this).parent().find('.duedates').removeClass("yellow").removeClass("red");
        $(this).parent().find('.icon-calendar').removeClass("yellow").removeClass("red");
      }
    }
  });
};
      
init_projects_list = function() {
  // Breeze.resize_workspaces();
	highlight_overdue_projects();
	$('.workspace.projects-list').on("focus", "#project_modal .person-email input", function(e, wasTriggered) {
	  var el, l, project_users, title;
  
	  l = $(this).closest('.person-group').find('.person-email input[type="email"]').filter(function() {
	    return this.value === "";
	  }).length;
	  if (l < 2) {
	    el = $('<div class="row"> <span class="person-icon"><i class="icon-user"></i></span> <span class="person-email">  <input type="email" name="invitees[]" class="" spellcheck="false" autocomplete="off" value="" placeholder="Email address"> <i class="icon-x-altx-alt person-pill" title="remove"></i> </span> </div>').appendTo(".person-group");
	    Breeze.user_count -= 1;

	  }
	});
	
  $('.project-fields-users-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: true,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    nonSelectedText: "Users",
    buttonClass: 'btn btn-link1',
    nSelectedText: '',
    nSelectedText1: 'Users',
    enableFiltering: true,
    enableHTML: true,
    enableCaseInsensitiveFiltering: true,
    enableClickableOptGroups: true,
    onInitialized: function(select, container) {
      var selected_values;
      selected_values = $(this.$select).data('selected-values');
      if (selected_values && selected_values.length > 0) {
        $(container).find('.multiselect-badge').text(selected_values.length);
      }
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onDropdownShown: function(e, i) {
      var select_el, selected_values, url, wrapper_el;
      $(".popover").remove();
      $('.custom-fields-button').removeData("bs.popover");
      select_el = $(this.$select);
      wrapper_el = $(this.$container);
      if (select_el.find('option').length <= 0) {
        selected_values = select_el.attr('data-selected-values');
        wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
        if (developement()) {
          url = "https://app.breeze.test:3006/users/select_field?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n2.herokuapp.com/users/select_field?auth_token=" + Breeze.current_user.token;
        }
        return $.ajax({
          type: "POST",
          url: url,
          data: {
            empty: true
          },
          success: function(data) {
            select_el.append($(data).find("select").html());
            select_el.multiselect('rebuild');
            wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
            return select_el.parent().find("input[type='text'].multiselect-search").focus();
          }
        });
      }
    }
  });
  $('#invite-role-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 600,
    nonSelectedText: "default",
    buttonClass: 'btn-material',
    nSelectedText: 'selected',
    multiple: false
  });
  $('.template-projects-select').multiselect({
    numberDisplayed: 1,
    maxHeight: 400,
    nonSelectedText: "none",
    buttonClass: 'btn-material',
    nSelectedText: '',
    enableFiltering: true,
    enableCaseInsensitiveFiltering: true,
    multiple: false,
    includeSelectAllOption: false,
    includeSelectAllIfMoreThan: 1,
    selectAllValue: "all",
    unselect: true,
    onInitialized: function(select, container) {
      var selected_values;
      selected_values = $(this.$select).data('selected-values');
      if (selected_values && selected_values.length > 0) {
        $(container).find('.multiselect-badge').text(selected_values.length);
      }
      return $(container).find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
    },
    onChange: function(option, container) {
      if ($('.template-projects-select').val().length > 0) {
        return $('.template-options').show();
      } else {
        return $('.template-options').hide();
      }
    },
    onDropdownShown: function(e, i) {
      var select_el, selected_values, url, wrapper_el;
      $(".popover").remove();
      $('.custom-fields-button').removeData("bs.popover");
      select_el = $(this.$select);
      wrapper_el = $(this.$container);
      if (select_el.find('option').length <= 1) {
        selected_values = select_el.attr('data-selected-values');
        wrapper_el.find('.dropdown-menu .loader').remove();
        wrapper_el.find('.dropdown-menu').append('<li class="loader">' + Breeze.global_loader + '</li>');
        if (Breeze.developement()) {
          url = "https://app.breeze.test:3006/projects/select_field?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n2.herokuapp.com/projects/select_field?auth_token=" + Breeze.current_user.token;
        }
        return $.ajax({
          type: "POST",
          url: url,
          data: {
            empty: true
          },
          success: function(data) {
            var samples;
            select_el.append('<option value="">none</option>');
            select_el.append($(data).find("select").html());
            samples = '<optgroup label="Sample projects"> <option value="142732">A simple board</option> <option value="142733">Time driven board</option> <option value="142734">Weekly retrospective</option> <option value="142735">Hiring funnel</option> <option value="142736">Product roadmap</option> <option value="142737">Company overview</option> </optgroup>';
            select_el.append(samples);
            select_el.multiselect('rebuild');
            return wrapper_el.find('li:not(.multiselect-filter)').wrapAll('<div class="options-wrapper"></div>');
          }
        });
      }
    }
  });
  $('.template-options-select').multiselect({
    numberDisplayed: 0,
    maxHeight: 500,
    includeSelectAllOption: false,
    includeSelectAllIfMoreThan: 3,
    selectAllValue: "all",
    allSelectedText: "everything",
    nonSelectedText: "nothing",
    buttonClass: 'btn-material',
    nSelectedText: '',
    nSelectedText1: 'some properties',
    enableFiltering: false,
    selectAllNumber: false
  });
  $('.project-new-extra [data-action="show-budget"]').on("click", function(e) {
    $('.project-budget-section').removeClass("hide");
    $(this).hide();
    return e.preventDefault();
  });
  $('.projects-index-dropdown').on("click", ".dropdown-toggle", function() {
    return $('.popover').remove();
  });
  $('.workspace.projects-list').on("click", '#projects-archive [data-action="delete"]', function(e) {
    var el, project, r, url;
    project = $(this).closest('.project-archive-dropdown').attr('data-project-id');
    el = $(this);
    r = confirm('Are you sure? All tasks and comments in this project will also be deleted!');
    if (r) {
      if (Breeze.developement()) {
        url = "https://app.breeze.test:3006" + $(this).attr("href") + "?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n5.herokuapp.com" + $(this).attr("href") + "?auth_token=" + Breeze.current_user.token;
      }
      $.ajax({
        type: 'GET',
        url: url
      });
      e.preventDefault();
      e.stopPropagation();
      el.closest('.project_name').remove();
    }
    return false;
  });
  $('.workspace.projects-list').on("click", '#projects-archive [data-action="reactivate"]', function() {
    var el, page, url;
    el = $(this);
    if ($('#projects-archive .load-more').length <= 0) {
      page = 999;
    } else {
      page = parseInt($.parseParams($('#projects-archive .load-more').attr("href")).page) - 1;
    }
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006" + $(this).attr("href") + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com" + $(this).attr("href") + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: 'GET',
      url: url,
      data: {
        page: page
      },
      beforeSend: function() {
        el.closest('.dropdown-menu').find('li:not(.loader)').css('visibility', 'hidden');
        return el.closest('.dropdown-menu').find('.loader').show();
      },
      success: function(data) {
        el.closest('.project_name').remove();
        $('.project-right').html($(data).find('.project-right').html());
        $('.projects-list[data-sorting="true"] .project_cards').sortable(Breeze.sort_opts_projects).sortable({
          connectWith: ".project_cards"
        });
        $('.projects-list[data-sorting="true"] .project_list').sortable(Breeze.sort_opts_projects).sortable({
          connectWith: ".project_list"
        });
        Breeze.make_workspace_sortable();
        Breeze.make_project_alpha_sorting();
        $('.projects-footer .archived-projects').addClass("open");
        return $("#projects-archive").show();
      }
    });
    return false;
  });
  $('.projects-footer .archived-projects').on("click", function() {
    var url;
    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
      $("#projects-archive").slideUp(function() {
        return $(this).empty();
      });
    } else {
      $(this).addClass("open");
      if (Breeze.developement()) {
        url = "https://app.breeze.test:3006/projects/archived_projects?auth_token=" + Breeze.current_user.token;
      } else {
        url = "https://breeze-node-n5.herokuapp.com/projects/archived_projects?auth_token=" + Breeze.current_user.token;
      }
      $.ajax({
        type: 'GET',
        url: url,
        beforeSend: function() {
          return $('.projects-footer .m-loader').css('visibility', 'visible');
        },
        success: function(data) {
          $('.projects-footer .m-loader').css('visibility', 'hidden');
          return $('#projects-archive').html(data).slideDown("fast");
        }
      });
    }
    return false;
  });
  $('.workspace.projects-list').on("click", '#projects-archive .load-more', function() {
    var el, url;
    el = $(this);
    if (Breeze.developement()) {
      url = "https://app.breeze.test:3006" + $(this).attr("data-href") + "&auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com" + $(this).attr("data-href") + "&auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: 'GET',
      url: url,
      beforeSend: function() {
        el.prop('disabled', true);
        return el.find('.progress-button').show();
      },
      success: function(data) {
        el.prop('disabled', false);
        el.find('.progress-button').hide();
        el.find('.js-ripple').removeClass("is-active");
        $('#projects-archive .load-more').remove();
        return $('#projects-archive').append(data);
      }
    });
    return false;
  });
  $('#new_project_form').on("click", ".cancel", function(evt, xhr, settings) {
    $("#project_modal").modal("hide");
    return evt.preventDefault();
  });
  $('#new_project_form').on("click", 'button[type="submit"]', function(evt, xhr, settings) {
    var url;
    // $('#new_project_form .person-email input:text').each(function(i) {
    //   if (!validate_email($(this).siblings("input:hidden").val())) {
    //     return $(this).parent().siblings(".person-icon").find("i").attr("class", "icon-minus");
    //   }
    // });
    if ($("#new_project_form .person-icon .icon-minus").length > 0) {
      return false;
    }
    $('#new_project_form').find('.c-button[type="submit"]').prop('disabled', true);
    $('#new_project_form').find('.c-button[type="submit"] .progress-button').show();
		
		url = "/projects?auth_token=" + Breeze.current_user.token;
		
    // if (Breeze.developement()) {
    //   url = "https://app.breeze.test:3006/projects?auth_token=" + Breeze.current_user.token;
    // } else {
    //   url = "https://breeze-node-n7.herokuapp.com/projects?auth_token=" + Breeze.current_user.token;
    // }
    $.ajax({
      type: "POST",
      url: url,
      data: $('#new_project_form').serialize(),
      success: function(data) {
				$("#project_modal").modal("hide");
				Turbolinks.visit("/projects/"+data)
        // if (data !== "project-clone-bg") {
          // $('#new_project_form').find('.c-button[type="submit"]').prop('disabled', false);
          // $('#new_project_form').find('.c-button[type="submit"] .progress-button').hide();
          // $('#new_project_form').find('.c-button[type="submit"] .js-ripple').removeClass("is-active");
          // $("#project_modal").modal('hide');
          // // History.pushState({
          // //   "static": true
          // // }, $("title").html(), "/projects/" + $(data).find(".project_head .project_name_group").data("project-id"));
          // $(".workspace").replaceWith(data);
          // $("#main_header .main-links .main.bold-font").removeClass("bold-font");
          // $('input, textarea').placeholder();
          // $('.sortable_stage').each(function() {
          //   $(this).find('.stage_class:visible').equalHeights();
          //   return $(this).css('min-height', $(this).find('.stage_class:visible').height() + 20);
          // });
          // Breeze.hide_first_swimlane();
          // $('.sortable_stage').each(function() {
          //   return $(this).find('.stage_class:visible .sortable').equalHeights();
          // });
          // $('.sortable_stage').equalWidths();
          // $.stages_resize();
          // Breeze.project = $('.project_head .project_name_group').attr('data-project-id');
          // Breeze.project_token = $('.project_head .project_name_group').attr('data-project-token');
          // Breeze.init_task_parser();
          // Breeze.make_sortable();
          // Breeze.init_fileupload();
          // Breeze.stickyHeaderTop = $('.card-sort').offset().top;
          // // if (Breeze.is_socket_connected()) {
          // //   Breeze.socket.emit("subscribe", Breeze.project_token);
          // // }
          // Breeze.view_width = $('.card-sort').width() - 220;
          // Breeze.view_height = $(window).height();
          // $(document).trigger("breeze:page", "project");
          // $(document).trigger("breeze:page", "comments");
          // $(document).trigger("breeze:page", "time_entries");
          // $(document).trigger("breeze:page", "todo_lists");
          // $(document).trigger("breeze:page", "todos");
          // $(document).trigger("breeze:page", "load-swimlanes");
          // $(document).trigger("breeze:page", "card_move_short");
          // if ($('#card_modal').length > 0) {
          //   $(document).trigger("breeze:page", "card_page");
          // }
          // Breeze.project_token = null;
          // Breeze.init_board_list_view();
          // Breeze.init_board_stages();
          // Breeze.css_p_asset = Breeze.css_asset;
          // Breeze.js_p_asset = Breeze.js_asset;
          // Breeze.project_board = true;
          // Breeze.project = $('.project_head .project_name_group').attr('data-project-id');
          // Breeze.project_token = $('.project_head .project_name_group').attr('data-project-token');
          // if (Breeze.is_socket_connected()) {
          //   Breeze.socket.emit("unsubscribe", Breeze.project_token_old);
          // }
          // if (Breeze.is_socket_connected()) {
          //   return Breeze.socket.emit("subscribe", Breeze.project_token);
          // }
        // }
      }
    });
    return false;
  });
  $('.workspace.projects-list').on("click", '#project_new', function(e) {
	
    var options, project_users, url;
    project_users = [];
		
		url = "/teams/users?auth_token=" + Breeze.current_user.token;
    // if (Breeze.developement()) {
    //   url = "https://app.breeze.test:3006/teams/users?auth_token=" + Breeze.current_user.token;
    // } else {
    //   url = "https://breeze-node-n.herokuapp.com/teams/users?auth_token=" + Breeze.current_user.token;
    // }
    // $.get(url, function(data) {
    //   return $("#project_modal").user_autocomplete(data);
    // });
    options = "";
    $('.project-workspace').each(function() {
      var name, val;
      val = $(this).attr('data-workspace-id');
      name = $(this).find('.workspace-text').text();
      if (!val) {
        val = "";
        name = "none";
      }
      return options += '<option value="' + val + '">' + name + '</option>';
    });
    $('.template-group-select').html(options);
    $('.template-group-select').multiselect("destroy");
    $('.template-group-select').multiselect({
      numberDisplayed: 1,
      maxHeight: 400,
      includeSelectAllOption: false,
      nonSelectedText: "group",
      nSelectedText: '',
      buttonClass: 'btn-material',
      enableFiltering: false,
      multiple: false,
      unselect: true
    });
    // $("html").css("overflow", "hidden");
    $('#project_modal').modal({
      dynamic: true
    });
    $('#project_modal').modal('show');
    $("#project_name").autosize();
    $("#project_description").autosize();
    if (!$("#project_name").data("key_binded")) {
      $("#project_name, #project_description").keydown(function(e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          return $(this).parent().submit();
        }
      });
    }
    $("#project_name").data("key_binded", true);
    $("#project_name").select();
    return e.preventDefault();
  });
  $('#project_modal').on("focus", '.budget input[name="project[budget_amount_display]"]', function(e) {
    var amount, rate, symbol, total;
    if ($('.budget input[name="project[budget_hours]"]').val() && $('.budget input[name="project[hourly_rate_display]"]').val()) {
      rate = $('.budget input[name="project[hourly_rate_display]"]').val();
      symbol = rate.match(/[^0-9-.,]/g);
      if (symbol != null) {
        symbol = symbol.join("");
      }
      amount = accounting.unformat(rate);
      total = amount * parseInt($('.budget input[name="project[budget_hours]"]').val());
       $(this).val(accounting.formatMoney(total, symbol, 0));
    }
  });
  $('#project_modal').on("click", 'input[type="submit"]', function(e) {
    var budget_amount, budget_amount_display, budget_hours, form, hourly_rate, hourly_rate_display, symbol;
    form = $(this).closest("form");
    budget_hours = form.find('input[name="project[budget_hours]"]');
    hourly_rate = form.find('input[name="project[hourly_rate]"]');
    hourly_rate_display = form.find('input[name="project[hourly_rate_display]"]');
    budget_amount = form.find('input[name="project[budget_amount]"]');
    budget_amount_display = form.find('input[name="project[budget_amount_display]"]');
    hourly_rate.val(accounting.unformat(hourly_rate_display.val()));
    budget_amount.val(accounting.unformat(budget_amount_display.val()));
    symbol = hourly_rate_display.val().match(/[^0-9-.,]/g);
    if (symbol == null) {
      symbol = budget_amount_display.val().match(/[^0-9-.,]/g);
    }
    if (symbol != null) {
      symbol = symbol.join("");
    }
    if (symbol != null) {
      form.find('input[name="project[currency_symbol]"]').val(symbol);
    }
    hourly_rate.attr("value", hourly_rate.val());
    hourly_rate_display.attr("value", hourly_rate_display.val());
    budget_amount.attr("value", budget_amount.val());
    budget_amount_display.attr("value", budget_amount_display.val());
    budget_hours.attr("value", budget_hours.val());
  });
};

Breeze.resize_workspaces = function() {
  $(".project_cards").each(function(e) {
    if ($(this).find('.drop-shadow').length <= 0) {
      return $(this).addClass('hidden-zone-minimal');
    } else {
      return $(this).removeClass('hidden-zone-minimal');
    }
  });
  $(".project_list").each(function(e) {
    if ($(this).find('.project_name').length <= 0) {
      return $(this).addClass('hidden-zone-minimal');
    } else {
      return $(this).removeClass('hidden-zone-minimal');
    }
  });
  $(".project-workspace").each(function(e) {
    if ($(this).find('.project_list').hasClass('hidden-zone-minimal') && $(this).find('.project_cards').hasClass('hidden-zone-minimal')) {
      return $(this).addClass('workspace-minimal');
    } else {
      return $(this).removeClass('workspace-minimal');
    }
  });
  if ($('.project-workspace-main .project_cards .drop-shadow').length <= 0 && $('.project-workspace-main .project_list .project_name').length <= 0) {
    return $('.project-workspace-main').addClass('hidden-zone-main');
  } else {
    return $('.project-workspace-main').removeClass('hidden-zone-main');
  }
};

Breeze.make_project_alpha_sorting = function() {
  if ($('.project-workspace').length > 1) {
    $('.projects-list[data-sorting="false"] .project_list .project_name,.projects-list[data-sorting="false"] .project_cards .drop-shadow').draggable({
      revert: "valid",
      revertDuration: 200,
      scroll: true,
      delay: /iPhone|iPod|iPad|Android|BlackBerry/.test(navigator.userAgent) ? 600 : 300,
      start: function(e, ui) {
        return $(ui.helper).css('z-index', '999999');
      }
    });
    return $('.projects-list[data-sorting="false"] .project-workspace').droppable({
      activeClass: 'ui-sortable-placeholder',
      accept: '.project_name,.drop-shadow',
      drop: function(e, ui) {
        var added, project, url, workspace;
        workspace = $(this).attr("data-workspace-id");
        if (workspace === $(ui.draggable).closest('.project-workspace').attr('data-workspace-id')) {
          $(ui.draggable).attr('style', '');
          return false;
        }
        if (workspace == null) {
          workspace = null;
        }
        project = ui.draggable.attr('data-project-id');
        ui.draggable.css('z-index', '1');
        if (Breeze.developement()) {
          url = "https://app.breeze.test:3006/projects/" + project + "?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n5.herokuapp.com/projects/" + project + "?auth_token=" + Breeze.current_user.token;
        }
        $.ajax({
          type: "PUT",
          url: url,
          queue: true,
          data: {
            project: {
              workspace_id: workspace
            }
          }
        });
        added = false;
        if ($(ui.draggable).hasClass('project_name')) {
          $(this).find('.project_name').each(function() {
            if ($(this).find('h3 a').text().toUpperCase() > $(ui.draggable).find('h3 a').text().toUpperCase()) {
              $(ui.draggable).insertBefore($(this)).attr('style', '');
              added = true;
              return false;
            }
          });
          if (!added) {
            $(ui.draggable).attr('style', '').appendTo($($(this).find('.project_list')));
          }
          added = false;
          $(this).find('.drop-shadow').each(function() {
            if ($(this).find('h4').text().toUpperCase() > $(ui.draggable).text().toUpperCase()) {
              $('.drop-shadow[data-project-id="' + project + '"]').insertBefore($(this));
              added = true;
              return false;
            }
          });
          if (!added) {
            $('.drop-shadow[data-project-id="' + project + '"]').appendTo($($(this).find('.project_cards')));
            $(this).find('.project_cards').show();
          }
        } else {
          $(this).find('.drop-shadow').each(function() {
            if ($(this).find('h4').text().toUpperCase() > $(ui.draggable).find('h4').text().toUpperCase()) {
              $(ui.draggable).insertBefore($(this)).attr('style', '');
              added = true;
              return false;
            }
          });
          if (!added) {
            $(ui.draggable).attr('style', '').appendTo($($(this).find('.project_cards')));
            $(this).find('.project_cards').show();
          }
          added = false;
          $(this).find('.project_name').each(function() {
            if ($(this).find('h3 a').text().toUpperCase() > $(ui.draggable).find('h4').text().toUpperCase()) {
              $('.project_name[data-project-id="' + project + '"]').insertBefore($(this)).attr('style', '');
              added = true;
              return false;
            }
          });
          if (!added) {
            $('.project_name[data-project-id="' + project + '"]').appendTo($($(this).find('.project_list')));
          }
        }
        Breeze.resize_workspaces();
        return false;
      }
    });
  }
};

//
// validate_email = function(email) {
//   var emailReg;
//   if (email === "") {
//     return true;
//   }
//   emailReg = /^([a-zA-Z0-9_\.\-\+\'])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
//   emailReg.test(email);
// };




// $.fn.user_autocomplete = function(users) {
//   var modal_id, project_users, title;
//   modal_id = this.attr("id");
//   project_users = $.map(users, function(n, i) {
//     n.label = $.user_name_email(n);
//     n.name = $.user_name(n);
//     return n;
//   });
//   title = "This person isn't in the system. Please enter their email address.";
//   $("#" + modal_id + " .person-email input:text").tooltip({
//     animation: false,
//     placement: "bottomleft",
//     trigger: "manual",
//     title: title
//   });
//   $("#" + modal_id + " .person-email input").tooltip("hide");
//   $("#" + modal_id + " .person-email input:text").typeahead({
//     source: project_users
//   });
//   return Breeze.email_valid = true;
// };