import {chooseDateFormat} from 'mobile/utils.js';
import {developement} from 'mobile/utils.js';

var fetch_page, init_page, notifications_latest;

$(document).ready(function() {
  var show_notification_popup;
  $(document).on("breeze:page", function(e, page) {
    if (page === "notifications_latest") {
      return notifications_latest();
    }
  });
  $(document).on("breeze:page", function(e, page) {
    if (page === "notifications") {
      return init_page();
    }
  });
  if ($('.container.notifications').length > 0) {
    init_page();
  }
  if ($('.workspace-notifications-empty').length > 0) {
    fetch_page();
  }
  $('#main_header').on("click", '[data-menu="notifications"]', function(e) {
    var el, url_host;
    el = $('.latest-notifications');
    if (el.length <= 0) {
      if (developement()) {
        url_host = "https://app.breeze.test:3006";
      } else {
        url_host = "https://breeze-node-n8.herokuapp.com";
      }
      return $.ajax({
        type: "GET",
        url: url_host + '/notifications/latest',
        data: {
          auth_token: Breeze.current_user.token
        },
        beforeSend: function(xhr) {
          return $('#notify-pop').html(Breeze.global_loader);
        },
        success: function(data) {
          $('.workspace').append(data);
          el = $('.latest-notifications');
          $('#notify-pop').html(el.html());
          return show_notification_popup(el);
        }
      });
    } else {
      return show_notification_popup(el);
    }
  });
  show_notification_popup = function(el) {
    if (el.find('.entry').length <= 0) {
      el.find('.body').append('<div class="entry empty">You\'ll see notifications here when you are mentioned or your subscribed tasks are updated.</div>');
      el.find('[data-action="clear-all"]').hide();
    } else if (el.find('.entry').length > 1) {
      el.find('[data-action="clear-all"]').show();
    }
    $('#notify-pop').html(el.html());
    if (!("Notification" in window) || (("Notification" in window) && (Notification.permission === "granted")) || (("Notification" in window) && (Notification.permission === "denied"))) {
      return $('#notify-pop [data-action="webpush"]').hide();
    } else {
      return $('#notify-pop [data-action="webpush"]').show();
    }
  };
  $('#notify-pop').on("click", '.comment-content a', function() {
    return $('#main_header .dropdown').removeClass("open");
  });
  $('#notify-pop').on("click", '[data-action="clear-all"]', function() {
    var url;
    $('#notify-pop .entry').remove();
    $('#main-notify .number-circle').html("").hide();
    $('.latest-notifications .entry').remove();
    $('#main_header .dropdown').removeClass("open");
    if (developement()) {
      url = "https://app.breeze.test:3006/notifications/0?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/notifications/0?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      url: url
    });
    return false;
  });
  $('#notify-pop').on("click", '[data-action="webpush"]', function() {
    if (Breeze.supportsPushNotifications()) {
      Breeze.check_webpush();
    } else {
      Notification.requestPermission(function(permission) {
        var url;
        if (permission === 'granted') {
          $('#notify-pop [data-action="webpush"]').hide();
        }
        if (developement()) {
          url = "https://app.breeze.test:3006/profile?auth_token=" + Breeze.current_user.token;
        } else {
          url = "https://breeze-node-n5.herokuapp.com/profile?auth_token=" + Breeze.current_user.token;
        }
        $.ajax({
          type: 'POST',
          url: url,
          queue: true,
          data: {
            user: {
              manual_webpush: true
            }
          }
        });
      });
    }
    return false;
  });
  $('#notify-pop').on("click", '[data-action="see-all"]', function() {
    return $('#main_header .dropdown').removeClass("open");
  });
   $('#notify-pop').on("click", '[data-action="clear"]', function() {
    var url;
    if (developement()) {
      url = "https://app.breeze.test:3006/notifications/" + $(this).parent().attr('data-id') + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/notifications/" + $(this).parent().attr('data-id') + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      data: {
        notification: {
          marked: true
        }
      },
      url: url
    });
    $(this).parent().slideUp(function() {
      var count;
      $('.latest-notifications [data-id="' + $(this).attr('data-id') + '"]').remove();
      $(this).remove();
      count = $('.latest-notifications').data('notifications-count') - 1;
      if (count <= 0) {
        count = "";
      }
      $('#main-notify .number-circle').html(count);
      $('.latest-notifications').data('notifications-count', count);
      if ($('#notify-pop .entry').length <= 0) {
        $('#main_header .dropdown').removeClass("open");
        return $('#main-notify .number-circle').hide();
      }
    });
    return false;
  });
});

fetch_page = function() {
  var url;
  if ($('.workspace-notifications-empty').length > 0) {
    if (developement()) {
      url = ("https://app.breeze.test:3006" + location.pathname + "?&auth_token=") + Breeze.current_user.token;
    } else {
      url = ("https://breeze-node-n5.herokuapp.com" + location.pathname + "?&auth_token=") + Breeze.current_user.token;
    }
    return $.ajax({
      type: 'GET',
      url: url,
      data: {
        partial: true
      },
      beforeSend: function() {
        $('.workspace').append(Breeze.global_loader);
      },
      success: function(data) {
        $(".workspace").removeClass('workspace-notifications-empty');
        $(".workspace").html($(data).html());
        $(document).trigger("breeze:page", "notifications");
        return $(document).trigger("breeze:page", "update-global-counters");
      }
    });
  }
};

init_page = function() {
  $('.container.notifications').on("click", '.entry .delete-notification', function() {
    var el, url;
    el = $(this).closest('.entry');
    if (developement()) {
      url = "https://app.breeze.test:3006/notifications/" + el.attr('data-id') + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/notifications/" + el.attr('data-id') + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "DELETE",
      url: url
    });
    el.remove();
    return false;
  });
  $('.container.notifications').on("click", '.entry .mark-notification', function() {
    var el, url, count;
    el = $(this).closest('.entry');
    if (!$(this).data('marked')) {
	    count = $('.latest-notifications').data('notifications-count') - 1;
			
      $(this).html("Mark as unread");
      $(this).data('marked', true);
    } else {
	    count = $('.latest-notifications').data('notifications-count') + 1;
			
      $(this).html("Mark as read");
      $(this).data('marked', false);
    }
    if (developement()) {
      url = "https://app.breeze.test:3006/notifications/" + el.attr('data-id') + "?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/notifications/" + el.attr('data-id') + "?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      data: {
        notification: {
          marked: $(this).data('marked')
        }
      },
      url: url
    });
    el.toggleClass('marked-notification');
		
    if (count <= 0) {
      count = "";
    }
    $('#main-notify .number-circle').html(count);
    $('.latest-notifications').data('notifications-count', count);
		
    return false;
  });
  $('.container.notifications').on("click", '.show-read', function() {
    var url;
			
		
		$('.notifications .panel').append(Breeze.global_loader)
		$('.notifications .body').css("opacity",0)
		
		$('.notifications .nav-btn').removeClass("active")
		$(this).addClass("active")
		
    if (developement()) {
      url = "https://app.breeze.test:3006/notifications?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/notifications?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "GET",
      url: url,
      data: {
        unread: $(this).data('unread'),
        partial: true
      },
      success: function(data) {
        $('.workspace').html($(data).html());
				$('.notifications-pagination-container .m-loader').remove()
        return init_page();
      }
    });
    return false;
  });
  $('.container.notifications').on("click", '.mark-all', function() {
    var url;
    if (developement()) {
      url = "https://app.breeze.test:3006/notifications/0?auth_token=" + Breeze.current_user.token;
    } else {
      url = "https://breeze-node-n5.herokuapp.com/notifications/0?auth_token=" + Breeze.current_user.token;
    }
    $.ajax({
      type: "PUT",
      url: url
    });
    $('.notifications .entry').addClass('marked-notification');
    $('.notifications .entry .mark-notification').html("Mark as unread");
    $('.notifications .entry .mark-notification').data('marked', true);
    $('#notify-pop .entry').remove();
    $('#main-notify .number-circle').html("").hide();
    $('.latest-notifications .entry').remove();
    return false;
  });
  // $('.container.notifications').on("click", ".pagination a1", function() {
  //   var url;
  //   if (developement()) {
  //     url = "https://app.breeze.test:3006";
  //   } else {
  //     url = "https://breeze-node-n5.herokuapp.com";
  //   }
  //   $.ajax({
  //     type: "GET",
  //     data: {
  //       partial: true
  //     },
  //     url: url + $(this).attr('href'),
  //     beforeSend: function(xhr) {
  //       $('.notifications .m-loader').show();
  //     },
  //     success: function(data) {
  //       $(".workspace").replaceWith(data);
  //       init_page();
  //     }
  //   });
  //   return false;
  // });
};

notifications_latest = function() {
  return $(document).trigger("breeze:page", "update-global-counters");
};

// ---
// generated by coffee-script 1.9.2